import axios from 'axios';
import config from "../../config";
import authHeader from "./auth-header";

const API_URL = config.API+'/case/';

class CaseService {

    deleteCase (id) {
        return axios.delete(API_URL + id,{ headers: authHeader() })
    }

    updateCaseStatus(data) {
        return axios.put(API_URL + data.caseId, data,{ headers: authHeader() })
    }

    updateCase(data, tag) {
        return axios.put(API_URL + data.caseId, {'tag': tag, 'data':data},{ headers: authHeader() })
    }

    saveCase(data) {
        return axios.post(API_URL + 'saveCase', data, { headers: authHeader() });
    }

    editCase(data, caseId) {
        return axios.put(API_URL + 'editCase/' + caseId, data, { headers: authHeader() });
    }

    loadAllCases() {
        return axios.get(API_URL + 'loadAllCases',{ headers: authHeader() });
    }

    caseByUserId(userId){
        return axios.get(API_URL + userId,{ headers: authHeader() })
    }
    caseById(caseId){
        return axios.get(API_URL +'caseById/'+ caseId,{ headers: authHeader() })
    }

}

export default new CaseService();
