<template>
  <div>

    <el-row >
      <a>
        <el-select v-model="neoSearch.label"
                   :no-match-text="$t('Ничего не найдено')"
                   multiple
                   :placeholder="$t('Любой тип')">
          <el-option
              v-for="item in Object.keys(ontology.nodes)"
              :key="item"
              :label="ontology.nodes[item].label"
              :value="item">
          </el-option>
        </el-select>

        <el-select v-model="neoSearch.property"
                   multiple
                   style="margin-left: 8px"
                   value-key="attribute"
                   v-if="neoSearch.label !== [] && neoSearch.type === 'noID'"
                   :no-match-text="$t('Ничего не найдено')"
                   :placeholder="$t('Любой атрибут')">
          <el-option
                  v-for="item in createAttributes"
                  :key="item.attribute"
                  :label="item.attribute"
                  :value="item">
            <span style="float: left">{{ item.attribute }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
          </el-option>
        </el-select>
      </a>
      <a style="margin-left: 8px">
        <el-radio-group v-model="neoSearch.type">
          <el-radio-button label="noID">{{ $t('Общий') }}</el-radio-button>
          <el-radio-button label="ID">{{ $t('По ID') }}</el-radio-button>
        </el-radio-group>
      </a>
    </el-row>

    <el-row>
      <el-col :span="21">
        <el-input v-bind:type="(neoSearch.type === 'ID') ? 'number' : 'text'" placeholder="..." v-model="neoSearch.query"></el-input>

      </el-col>
      <el-col :span="3">
        <el-button @click="startSearchFromNeo"  type="primary">{{ $t('Поиск') }}</el-button>
      </el-col>
    </el-row>
    <div   v-loading="waitForSearch" :style="{width: '100%', overflow: 'auto', 'margin-top': '8px'}">
      <el-table
              v-show="!waitForSearch"
              ref="multipletableref"
              :data="paginateData"
              @selection-change="handleSelectionChange"
              :style="{width: '100%'}">
        <el-table-column type="expand">
          <template #default="props">
            <ElementInfo v-if="props.row.type !== undefined" style="width: 500px" :element="{id: props.row.id, name: props.row.type, element: 'ontology'}">
            </ElementInfo>
          </template>
        </el-table-column>
        <el-table-column
                v-if="selectionOn"
                type="selection"
                width="55">
        </el-table-column>
        <el-table-column
                prop="id"
                label="ID">
        </el-table-column>
        <el-table-column
                prop="type"
                :label="$t('Тип')">
          <template #default="props">
            {{ontology.nodes[props.row.type].label}}
          </template>
        </el-table-column>
        <el-table-column
                prop="response"
                label="">
          <template #default="props">
            <span v-html="selectQuery(props.row)"> </span>
          </template>
        </el-table-column>
        <el-table-column
                v-if="!selectionOn"
                fixed
                label="">
          <template #default="props">
            <el-button @click="addToCart(props.row)">{{ $t('В избранное') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>



    <el-pagination
        background
        class="mt-3"
        type="primary"
        layout="prev, pager, next"
        :total="totalItems"
        :current-page = "page"
        :page-size= "perPage"
        @current-change = "pageChange">

    </el-pagination>
  </div>
</template>

<script>
  import VisController from '../../controllers/vis.controller'
  import ElementInfo from '../Constructor/ElementInfo'
  import OntologyController from "../../controllers/ontology.controller";
  import CashController from "../../controllers/cash.controller";

  export default {
  name: 'NeoSearch',
    components: {
      ElementInfo
    },
  data() {
    return {
      height: Math.max(
              document.documentElement.clientHeight,
              window.innerHeight || 0
      ) - 150,
      neoSearch: {
        label: [],
        property: [],
        type: 'noID',
        query: '',
        data: []
      },
      ontology: {
        nodes: {},
        links: {}
      },
      controller: null,
      ontologyController: null,
      cashController: null,
      multipleSelection: [],
      waitForSearch: false,
      page: 1,
      start: 0,
      end: 0,
      perPage: 10,
      totalItems: 0
    }
  },
    props: {
      selectionOn: {
        type: Boolean,
        default: function () {
          return false
        }
      },
      selectedAims: {
        type: Array,
        default: function() {
          return []
        }
      }
  },
  computed: {
    paginateData () {
      if (this.page*this.perPage < this.totalItems) {
         return this.neoSearch.data.slice((this.page - 1) * this.perPage, this.page*this.perPage)
      }
      return this.neoSearch.data.slice((this.page - 1) * this.perPage, this.neoSearch.length)

    },
    createAttributes () {
      let attrs = []
      for (let label of this.neoSearch.label) {
        for (let key of Object.keys(this.ontology.nodes[label].properties)) {
          attrs.push({
            attribute: key,
            label: label
          })
        }
      }
      return attrs
    }
  },
  
    updated() {
      const isNeoNodeInAims = (neoNodeId, aims) => {
        for (const aim of aims) {
            if (aim.id == neoNodeId) return true
        } 
        return false
      }

      const vm = this

      vm.paginateData.forEach( (neoNode) => {
        if (isNeoNodeInAims(neoNode.id, vm.selectedAims)) {
          vm.$refs.multipletableref.toggleRowSelection(neoNode, "selected")
        } 
      })
    },
    beforeMount() {
      this.ontologyController = new OntologyController()
      this.ontology = this.ontologyController.getOntology()
    },
    mounted() {
    this.controller = new VisController()
    this.cashController = new CashController()
    this.page = 1
      window.addEventListener('keydown', (e) => {
        if (e.key == 'Enter') {
          this.startSearchFromNeo()
        }
      });
  },
  methods: {
    addToCart (row) {
      this.$emit('add-to-cart', {
        name: row.type  + ' : ' + row.id,
        id:  row.id,
        type: row.type,
        text: '',
        class:'element-cart-card'
      })
    },
    selectQuery (row) {
      let q = row.query || "";
      let re = new RegExp(q.toLowerCase(), 'g')
      let str = row.response.toLowerCase()
      str = str.replace(re, "$!$")
      let positions = str.split("$!$")
      let newStr = ''
      let start = 0
      let newPositions = []
      for (let i = 0; i < positions.length - 1; i++) {
        let pos = positions[i]
        newPositions.push(row.response.slice(start, start + pos.length))
        newPositions.push("<b>" + row.response.slice(start + pos.length, start + pos.length + row.query.length)
                + "</b>")
        start = start + pos.length + row.query.length
      }
      newPositions.push(positions[positions.length - 1])
      newStr = newPositions.join('')
      return newStr
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChange(page) {
      this.page = page
    },
    startSearchFromNeo () {
      const vm = this
      vm.neoSearch.data = []
      this.waitForSearch = true
      this.neoSearch['revealed'] = this.cashController.getCashStore().revealed.elements,
      this.controller.searchNodeByString(this.neoSearch).then( (response) => {
        for (let element of response.data.result) {
          let resp = []
          for (let attr of element[2]) {
            resp.push(attr[0] + ': ' + attr[1])
          }
          vm.neoSearch.data.push({
            query: response.data.query,
            id: element[0],
            type: element[1][0],
            response: resp.join(', ')
          })
        }
        vm.totalItems = vm.neoSearch.data.length
        vm.waitForSearch = false
      })
    },
  },
  
};
</script>

<style scoped>

.el-transfer-panel{
    width: 300px;
  }


</style>
