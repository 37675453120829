import Neo4jService from '../services/neo4j.service'
import CaseService from '../services/case.service'
import UserService from '../services/user.service'
import {store} from "../store";

export default class CaseController {
    constructor() {
    }

    deleteCase(id) {
        return CaseService.deleteCase(id)

    }
    // TODO этот метод неиспользуется нигде
    updateCaseStatus(data) {
        return CaseService.updateCaseStatus(data)
    }

    updateCase(data, tag) {
        return CaseService.updateCase(data, tag)
    }

    getNodeByID(id) {
        return Neo4jService.getNodeByID(id)
    }

    getAllUsers(search) {
        return UserService.getAllUsers(search)
    }

    searchNodeByString(data) {
        return Neo4jService.searchNodeByString(data)
    }

    saveCase(data) {
        return CaseService.saveCase(data)
    }

    duplicateCase(data) {
        return CaseService.saveCase(data)
    }

    editCase(data, caseId) {
        return CaseService.editCase(data, caseId)
    }

    loadAllCases() {
        return CaseService.loadAllCases().then()
    }

    getCart() {
        return store.getters['cartStore/getCart']
    }

    async caseByUserId(userId) {
        let userCases = []
        return CaseService.caseByUserId(userId).then(
            function (response) {
                for (let Case of response.data) {
                    userCases.push({
                        aims: Case.aims, // список ID узлов
                        name: Case.name, // имя кейсы
                        difficulty: Case.difficulty, // сложность 1-5, число
                        spread_: Case.spread_, // распространеннсть 1-5, число
                        duration: Case.duration + ' минут', // длительность в минутах, число
                        date: Case.date, // дата изменения
                        caseStatus: Case.status, // status zadachi true in progress
                        caseId: Case._id,
                        IsStarted: false,
                        ontologyId: Case.ontologyId,
                        chatLink: Case.chatLink,
                        html: Case.html,
                        caseNumberToHide:Case.caseNumberToHide
                    })
                }
                return userCases
            })
    }
    caseById(caseId){
        return CaseService.caseById(caseId)
    }
}
