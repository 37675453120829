import Neo4jService from '../services/neo4j.service'
import CaseSessionService from '../services/caseSession.service'
import {store} from "../store";

export default class VisController {
    constructor() {
    }

    getEdgesWindow(data) {
        return Neo4jService.getEdgesWindow(data)
    }

    getNode(data){
        return Neo4jService.getNode(data)
    }


    getLink (data){
        return Neo4jService.getLink(data)
    }

    getSubgraphMap(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'getSubgraphMap',
            name: 'Инструмент "Карта"', time: Date.now()})
        CaseSessionService.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
        return Neo4jService.getSubgraphMap(data)
    }

    expandNode(data) {
        return Neo4jService.expandNode(data)
    }

    getAttributeNodes(data) {
        return Neo4jService.getAttributeNodes(data)
    }

    getLatLngNode(data){
        return Neo4jService.getLatLngNode(data)
    }

    getLinkSourcesByNode(data) {
        return Neo4jService.getLinkSourcesByNode(data)
    }

    revealLinks(data) {
        return Neo4jService.revealLinks(data)
    }

    getSimpleEdges (data) {
        return Neo4jService.getSimpleEdges(data)
    }

    getVGraph(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'getVGraph',
            name: 'Инструмент "Граф по пути"', time: Date.now()})
        CaseSessionService.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
        return Neo4jService.getVGraph(data)
    }

    searchNodeByString(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'searchNodeByString',
            name: 'Поиск по базе', time: Date.now()})
      let caseSession = store.getters['sessionStore/getCurrentSession']
        CaseSessionService.updateCaseSession(caseSession)
      // console.log('поиск по строке, сессия=',caseSession),
      // console.log('поиск по строке, bunbertohide=',caseSession.caseNumberToHide),
      // console.log('поиск по строке, data=',data)

        return Neo4jService.searchNodeByString(data)
    }

    searchNodeByIdList(data) {
        return Neo4jService.searchNodeByIdList(data)
    }

    searchSubgraphByString(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'searchSubgraphByString',
            name: 'Инструмент "Список"', time: Date.now()})
        CaseSessionService.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
        return Neo4jService.searchSubgraphByString(data)
    }

    getSubgraphGraph(data) {
        store.commit('sessionStore/sessionAddLog', {action: 'getSubgraphGraph',
            name: 'Инструмент "Граф"', time: Date.now()})
        store.commit('sessionStore/sessionAddLogQuery')
        CaseSessionService.updateCaseSession(store.getters['sessionStore/getCurrentSession'])
        return Neo4jService.getSubgraphGraph(data)
    }
}
