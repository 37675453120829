<template>
  <div id="elementInfo" style="width: auto">
    <el-card  class="card"

    >
      <template #header>
        <div>
          <span style="font-size: 14px">{{nodeWindow.element.label}} : {{nodeWindow.element.id}}</span>
        </div>
      </template>

      <div v-for="attr in Object.keys(nodeWindow.element.attributes)" :key="attr"  style="text-align: start; font-size: 14px">
        <div class="break-long-words" style="font-size: 12px">
          <b>{{ attr }}</b>  {{': ' + nodeWindow.element.attributes[attr] }}
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>

import VisController from "../../controllers/vis.controller";
import Utils from "../../services/utils";
import { ElCard } from 'element-plus'
// import { ElLoading } from 'element-plus'
import OntologyController from "../../controllers/ontology.controller";

export default {
  props: {
    element: {
      type: String,
      default: function () {
        return null
      }
    }
  },
  name: 'ElementInfo',
  components: {
    ElCard
  },
    data () {
    return {
      tableColumns: [],
      tableData: [],
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ) * 1 / 4 + 16,
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ) - 56,
      nodeWindow: {
        loading: true,
        element: {
          attributes: {}
        }
      },
      ontology: null,
      visController: null,
      ontologyController: null
    }
  },
  mounted() {
    this.ontologyController = new OntologyController()
    this.ontology = this.ontologyController.getOntology()
    this.visController = new VisController()
    if (this.element !== null) {
      this.init()
    }
  },
  methods: {
    init() {
      this.nodeWindow.loading = true
      const vm = this
      let element = {
        id: parseInt(this.element.id),
        label: this.element.name,
        element:  this.element.element
      }
      if (element.element === 'ontology') {
        this.visController.getNode({element: element}).then( (response) => {
          this.nodeWindow.element = response.data.element
          for (let attr in this.nodeWindow.element.attributes) {
            let numTest = Utils.testNumber(this.nodeWindow.element.attributes[attr])
            if (numTest !== null) {
              this.nodeWindow.element.attributes[attr] = this.nodeWindow.element.attributes[attr].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
          }
          this.nodeWindow.element.label = this.ontology.nodes[response.data.element.label].label
          vm.nodeWindow.loading = false
        })
      } else {
        this.visController.getLink({element: element}).then( (response) => {
          this.nodeWindow.element = response.data.element
          for (let attr in this.nodeWindow.element.attributes) {
            let numTest = Utils.testNumber(this.nodeWindow.element.attributes[attr])
            if (numTest !== null) {
              this.nodeWindow.element.attributes[attr] = this.nodeWindow.element.attributes[attr].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
          }
          this.nodeWindow.element.label = this.ontology.links[response.data.element.label].label
          vm.nodeWindow.loading = false
        })
      }

    },
  }
}
</script>
<style scoped src="../../assets/index.css"></style>
<style scoped>
>>> .card .el-card .el-card__header {
  padding: 4px 6px
}

>>> .card .el-card .el-card__body {
  padding: 6px
}

  .break-long-words {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: inline-block;
  }


.vue-ads-w-6 {
  padding-left: 8px;
  padding-right: 8px;
  width: initial;
}

</style>
